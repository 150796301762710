/* eslint-disable @typescript-eslint/no-var-requires */
import * as packageJson from '../../package.json';
const version = packageJson.version;

export const environment: any = {
  production: true,
  env: 'production',
  api: {
    host: 'https://pix.erpclass.com.br',
    prefix: '/api/v1',
  },
  socket: {
    url: 'https://pix.erpclass.com.br',
  },
  sentry: {
    dsn: 'https://5be671c6900afb708af6f8cf472dfe01@o228705.ingest.us.sentry.io/4507394498560000',
  },
  build: 'Version: ' + version + ' - Build Date: ' + new Date().toLocaleDateString('pt-BR') + ' ' + new Date().toLocaleTimeString('pt-BR'),
};
