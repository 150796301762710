import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserModel } from '@app/data';
import { ApiService } from '@app/gateway';
import { EVENTS_TYPE, EventsService } from '@app/services';
import { AUTH_CONSTANTS } from './auth.consts';
import { TokenService } from './token.service';

export class JwtTokens {
  accessToken: string;
  refreshToken: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private readonly router: Router,
    private readonly tokenService: TokenService,
    private readonly apiService: ApiService,
    private readonly eventsService: EventsService
  ) {}

  public async signup(email: string, password: string): Promise<any> {
    try {
      const path: any = AUTH_CONSTANTS.PATH_SIGNUP;
      const data: any = { email, password };
      return this.apiService.post({ path, data }).then((tokens: JwtTokens) => {
        this.tokenService.setToken(tokens.accessToken);
      });
    } catch (e: any) {
      throw new Error(e.message);
    }
  }

  public async signin(email: string, password: string): Promise<any> {
    try {
      const path: any = AUTH_CONSTANTS.PATH_SIGNIN;
      const data: any = { email, password };
      return this.apiService.post({ path, data }).then((tokens: JwtTokens) => {
        this.tokenService.setToken(tokens.accessToken);
      });
    } catch (e: any) {
      throw new Error(e.message);
    }
  }

  public async logout(): Promise<void> {
    try {
      const id: string = this.tokenService.id;
      if (id) {
        const path: any = `${AUTH_CONSTANTS.PATH_LOGOUT}/${id}`;
        await this.apiService.post({ path });
      }
      this.tokenService.clearToken();
      this.eventsService.broadcast<any>(EVENTS_TYPE.IS_AUTH, undefined);
    } catch (e: any) {
      throw new Error(e.message);
    }
  }

  public async reset(email: string): Promise<void> {
    try {
      const path: any = AUTH_CONSTANTS.PATH_RESET;
      const data: any = { email };
      await this.apiService.post({ path, data });
    } catch (e: any) {
      throw new Error(e.message);
    }
  }

  public async password(id: string, token: string, password: string): Promise<void> {
    try {
      const path: any = `${AUTH_CONSTANTS.PATH_PASSWORD}`;
      const query: any = { id, token };
      const data: any = { password };
      await this.apiService.post({ path, data, query });
    } catch (e: any) {
      throw new Error(e.message);
    }
  }

  public async verify(): Promise<void> {
    try {
      const path: any = `${AUTH_CONSTANTS.PATH_VERIFY}`;
      await this.apiService.post({ path });
    } catch (e: any) {
      throw new Error(e.message);
    }
  }

  public async confirm(verify: string): Promise<void> {
    try {
      const path: any = `${AUTH_CONSTANTS.PATH_CONFIRM}`;
      const query: any = { verify };
      await this.apiService.post({ path, query });
    } catch (e: any) {
      throw new Error(e.message);
    }
  }

  public async refresh(): Promise<any> {
    try {
      const path: any = AUTH_CONSTANTS.PATH_REFRESH;
      return this.apiService.get({ path }).then((tokens: JwtTokens) => {
        this.tokenService.setToken(tokens.accessToken);
      });
    } catch (e: any) {
      throw new Error(e.message);
    }
  }

  public async profile(): Promise<UserModel> {
    try {
      const path: any = AUTH_CONSTANTS.PATH_PROFILE;
      return this.apiService.get<UserModel>({ path });
    } catch (e: any) {
      throw new Error(e.message);
    }
  }

  public async home(): Promise<void> {
    const user: UserModel = await this.profile();
    if (user?.roles?.includes('USER')) {
      void this.router.navigateByUrl('/app/payments');
      return;
    }
    if (user?.roles?.includes('SUPORTE')) {
      void this.router.navigateByUrl('/app/suporte/accounts');
      return;
    }
    if (user?.roles?.includes('ADMIN')) {
      void this.router.navigateByUrl('/app/admin/statement');
      return;
    }
  }
}
