export enum SOCKET_TYPE {
  STATUS_CHANGED = 'STATUS_CHANGED',
  ACCOUNT_USER = 'ACCOUNT_USER',
  ACCOUNT_APPROVED = 'ACCOUNT_APPROVED',
  ACCOUNT_ERROR = 'ACCOUNT_ERROR',
  ALIAS_CREATED = 'ALIAS_CREATED',
  ALIAS_DELETED = 'ALIAS_DELETED',
  PAYMENT_CREATED = 'PAYMENT_CREATED',
  PAYMENT_RECEIVED = 'PAYMENT_RECEIVED',
  PAYMENT_REFUNDED = 'PAYMENT_REFUNDED',
  WITHDRAW_APPROVED = 'WITHDRAW_APPROVED',
  WITHDRAW_ERROR = 'WITHDRAW_ERROR',
}
