import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as utils from '@app/shared/utils';
import { catchError, lastValueFrom, Observable, of, switchMap, throwError } from 'rxjs';
import { CepInterface } from './cep.interface';

@Injectable({ providedIn: 'root' })
export class CepService {
  constructor(private readonly httpClient: HttpClient) {}

  public async searchCep(cep: string): Promise<CepInterface> {
    const response$: any = this.httpClient.get<CepInterface>(`https://viacep.com.br/ws/${cep}/json/`).pipe(
      catchError((error: HttpErrorResponse) => this.handleError(error)),
      switchMap((response: any) => this.handleResponse(response))
    );
    return lastValueFrom<any>(response$).then((response: CepInterface) => {
      return {
        cep: response.cep,
        logradouro: utils.normalize(response.logradouro.toUpperCase()),
        complemento: utils.normalize(response.complemento.toUpperCase()),
        bairro: utils.normalize(response.bairro.toUpperCase()),
        localidade: utils.normalize(response.localidade.toUpperCase()),
        uf: utils.normalize(response.uf.toUpperCase()),
        ddd: response.ddd,
        siafi: response.siafi,
        ibge: response.ibge,
        gia: response.gia,
      };
    });
  }

  protected handleError(error: HttpErrorResponse): any {
    return throwError(() => (error.status === 0 ? { code: 0, message: 'Serviço indisponível' } : error));
  }

  protected handleResponse(response: any): Observable<any> {
    return of(response);
  }
}
