{
  "name": "app",
  "version": "1.0.0",
  "description": "app",
  "author": {
    "name": "Dener Rocha",
    "email": "denernun@gmail.com"
  },
  "repository": {
    "type": "git",
    "url": "https://github.com/softclass/erpclass-pay.git"
  },
  "bugs": {
    "url": "https://github.com/softclass/erpclass-pay/issues"
  },
  "private": true,
  "license": "UNLICENSED",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --configuration=development --ssl --ssl-cert=./certs/localhost.crt --ssl-key=./certs/localhost.key",
    "build": "ng build --configuration=production && npm run sentry:sourcemaps",
    "lint": "eslint --color -c .eslintrc.json --ext .ts,.html ./src/",
    "lint-fix": "eslint --color --fix -c .eslintrc.json --fix --ext .ts,.html ./src/",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org softclass --project erpclass-pay ./src && sentry-cli sourcemaps upload --org softclass --project erpclass-pay ./src",
    "map-analyzer": "ng build --configuration=production --source-map",
    "map-explorer": "source-map-explorer dist/**/*.js"
  },
  "dependencies": {
    "@angular/animations": "^18.0.5",
    "@angular/common": "^18.0.5",
    "@angular/compiler": "^18.0.5",
    "@angular/core": "^18.0.5",
    "@angular/forms": "^18.0.5",
    "@angular/platform-browser": "^18.0.5",
    "@angular/platform-browser-dynamic": "^18.0.5",
    "@angular/router": "^18.0.5",
    "@auth0/angular-jwt": "^5.2.0",
    "@ctrl/ngx-rightclick": "^4.0.0",
    "@faker-js/faker": "^8.4.1",
    "@fnando/cnpj": "^1.0.2",
    "@fnando/cpf": "^1.0.2",
    "@fortawesome/fontawesome-free": "^6.5.2",
    "@iplab/ngx-file-upload": "^18.0.0",
    "@ng-select/ng-select": "^13.4.1",
    "@ngrx/effects": "^18.0.1",
    "@ngrx/store": "^18.0.1",
    "@ngrx/store-devtools": "^18.0.1",
    "@ngspot/ngx-errors": "^3.2.3",
    "@rxweb/reactive-form-validators": "^13.0.1",
    "@sentry/angular-ivy": "^7.114.0",
    "@sentry/cli": "^2.32.1",
    "accounting": "^0.4.1",
    "apexcharts": "^3.49.2",
    "core-js": "~3.37.1",
    "dayjs": "^1.11.11",
    "decimal.js": "^10.4.3",
    "file-saver": "^2.0.5",
    "jwt-decode": "^4.0.0",
    "lodash": "^4.17.21",
    "ng-apexcharts": "^1.11.0",
    "ngx-bootstrap": "^12.0.0",
    "ngx-currency": "^18.0.0",
    "ngx-device-detector": "^8.0.0",
    "ngx-file-drop": "^16.0.0",
    "ngx-loading": "^17.0.0",
    "ngx-mask": "^17.0.8",
    "ngx-pagination": "^6.0.3",
    "ngx-toastr": "^19.0.0",
    "ngx-webstorage": "^18.0.0",
    "perfect-scrollbar": "^1.5.5",
    "pretty-print-json": "^3.0.1",
    "replace-in-file": "^8.0.2",
    "rxjs": "~7.8.1",
    "socket.io-client": "^4.7.5",
    "sweetalert2": "^11.12.1",
    "ts-debug": "^1.3.0",
    "tslib": "^2.6.3",
    "uuid": "^10.0.0",
    "validator": "^13.12.0",
    "xlsx": "^0.18.5",
    "zone.js": "~0.14.7"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.0.6",
    "@angular-eslint/builder": "^18.1.0",
    "@angular-eslint/eslint-plugin": "^18.1.0",
    "@angular-eslint/eslint-plugin-template": "^18.1.0",
    "@angular-eslint/schematics": "^18.1.0",
    "@angular-eslint/template-parser": "^18.1.0",
    "@angular/cli": "^18.0.6",
    "@angular/compiler-cli": "^18.0.5",
    "@angular/language-service": "^18.0.5",
    "@rxweb/types": "^1.0.8",
    "@types/accounting": "^0.4.5",
    "@types/core-js": "~2.5.8",
    "@types/eslint": "^8.56.10",
    "@types/eslint-plugin-prettier": "^3.1.3",
    "@types/file-saver": "^2.0.7",
    "@types/jest": "^29.5.12",
    "@types/lodash": "^4.17.6",
    "@types/node": "^20.14.9",
    "@types/prettier": "^3.0.0",
    "@types/uuid": "^10.0.0",
    "@types/validator": "^13.12.0",
    "@typescript-eslint/eslint-plugin": "^7.15.0",
    "@typescript-eslint/parser": "^7.15.0",
    "copyfiles": "^2.4.1",
    "eslint": "8.56.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-prettier": "^5.1.3",
    "prettier": "^3.3.2",
    "prettier-eslint": "^16.3.0",
    "source-map-explorer": "^2.5.3",
    "ts-node": "^10.9.2",
    "typescript": "^5.4.5"
  }
}
