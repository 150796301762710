import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { io, ManagerOptions, Socket, SocketOptions } from 'socket.io-client';
import { SOCKET_TYPE } from './socket.types';

@Injectable({ providedIn: 'root' })
export class SocketService {
  private wss: Socket;

  constructor() {
    this.connect();
  }

  private connect(): void {
    const url: string = `${environment.socket.url}`;
    const options: Partial<ManagerOptions & SocketOptions> = {
      transports: ['websocket'],
      secure: true,
    };
    this.wss = io(url, options);
  }

  public get connected(): boolean {
    return this.wss.connected;
  }

  public emit(event: string, args: any): void {
    this.wss.emit(event, args);
  }

  public on(event: SOCKET_TYPE): Observable<any> {
    return new Observable((observer: any): any => {
      this.wss.on(event, (message: any) => {
        observer.next(message);
      });
    });
  }
}
